import React, { useEffect, useRef, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';

import 'primeicons/primeicons.css';

const UpdatePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
 
  const navigate = useNavigate();

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
         
            marginBottom: 20,
          }}
        >
          <h1>تغيير كلمة المرور</h1>
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>كلمة المرور القديمة</p>
          <InputText
            value={oldPassword}
            type='password'

            onChange={(e) => setOldPassword(e.target.value)}
            style={{ width: '100%' }}
          />
  
  <p style={{ fontWeight: 'bold', marginTop: 20 }}>كلمة المرور الجديدة</p>
          <InputText
            value={newPassword}
            type='password'
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ width: '100%' }}
          />


<Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
              marginTop:20
            }}
            onClick={async () => {
          
              const response=await Apis.resetPasswordAdmin(oldPassword,newPassword)
              
              console.log(response)
              
              if(response.status=="success"){
                toast.success(response.msg);
                navigate("/")
              }
              else
              {
                toast.error(response.msg);

              }
            }}
          >
          تغيير
          </Button>
        </div>
  
      </div>
      <div>
     
    </div>
    </div>
  );
  
};

export default UpdatePassword;
