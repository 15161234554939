import {  toast } from 'react-toastify';
//const url = config.apiUrl;
const url = "https://api.tiashipping.com/api/";



export default {
 
  UpdatePassword: async (currentPassword,newPassword) => {
    return fetch(url + "updatePassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        currentPassword,newPassword
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
    
  adminLogin: async (username, password) => {
    return fetch(url + "adminlogin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        username, password
            }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getUsers: async () => {
    return fetch(url + "getUsers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getOrders: async () => {
    return fetch(url + "adminorders", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getDictionary: async () => {
    return fetch(url + "getDictionary", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteNews: async (id) => {
    return fetch(url + "deleteNews?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addUser: async ( name,username,mobile,password,address,idnum) => {
    return fetch(url + "addUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        name,username,mobile,password,address,idnum
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addUserAttachment: async ( user,file) => {
    return fetch(url + "addUserAttachment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        user,file
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getChat: async (id) => {
    return fetch(url + "getChat?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addOrderAttachment: async ( order,file) => {
    return fetch(url + "addOrderAttachment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        order,file
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  createOrder: async (     ExpectedShipDate,
    ExpectedDelivery,
    OtherCost,
    ProductsCost,
    ShippingCost,
    OrderType,
    OrderSize,
    ShippingTo,
    ShippingFrom,
    TrackingNum,
    selectedUser,uploaddate) => {
    return fetch(url + "createOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        ExpectedShipDate,
        ExpectedDelivery,
        OtherCost,
        ProductsCost,
        ShippingCost,
        OrderType,
        OrderSize,
        ShippingTo,
        ShippingFrom,
        TrackingNum,
        selectedUser,
        uploaddate
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendNotification: async (title,body,users) => {
    return fetch(url + "sendNotification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body,users
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addHistory: async (id,body) => {
    return fetch(url + "addHistory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        id,body
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editUser: async ( name,username,mobile,password,address,idnum,userId) => {
    return fetch(url + "editUser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        name,username,mobile,password,address,idnum,userId
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editDictionary: async (english,arabic,id) => {
    return fetch(url + "editDictionary", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        english,arabic,id
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  editOrder: async (editTrackingNum,editShippingFrom,editShippingTo,editOrderType,editOrderSize,editShippingCost,editProductsCost,editOtherCost,editExpectedDelivery,editExpectedShipDate,id,EditUploadDate) => {
    return fetch(url + "editOrder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        editTrackingNum,editShippingFrom,editShippingTo,editOrderType,editOrderSize,editShippingCost,editProductsCost,editOtherCost,editExpectedDelivery,editExpectedShipDate,id,EditUploadDate
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  unBlockUser: async (id) => {
    return fetch(url + "unBlockUser?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  BlockUser: async (id) => {
    return fetch(url + "BlockUser?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getUserAttachments: async (id) => {
    return fetch(url + "userattachments?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getOrderAttachments: async (id) => {
    return fetch(url + "getOrderAttachments?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteAttachment: async (id) => {
    return fetch(url + "deleteAttachment?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getOrderHistory: async (id) => {
    return fetch(url + "orderhistory?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteHistory: async (id) => {
    return fetch(url + "deleteHistory?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteOrder: async (id) => {
    return fetch(url + "deleteOrder?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getEvents: async () => {
    return fetch(url + "getEvents", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteEvent: async (id) => {
    return fetch(url + "deleteEvent?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addEvent: async (title,body,image) => {
    return fetch(url + "addEvent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body,image
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  resetPasswordAdmin: async (oldPassword,newPassword) => {
    return fetch(url + "resetPasswordAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        oldPassword,newPassword
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getEventComments: async (id) => {
    return fetch(url + "getEventComments?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deleteEventComment: async (id) => {
    return fetch(url + "deleteEventComment?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPolls: async () => {
    return fetch(url + "getPolls", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPoll: async (id) => {
    return fetch(url + "getPoll?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPollStats: async (id) => {
    return fetch(url + "getPollStats?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addPoll: async (title,body) => {
    return fetch(url + "addPoll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        title,body
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deletePoll: async (id) => {
    return fetch(url + "deletePoll?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addQuestionToPoll: async (id,question) => {
    return fetch(url + "addQuestionToPoll", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        id,question
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPollQuestions: async (id) => {
    return fetch(url + "getPollQuestions?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  getPollQuestionOptions: async (id) => {
    return fetch(url + "getPollQuestionOptions?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  addOptionToPollQuestion: async (id,option) => {
    return fetch(url + "addOptionToPollQuestion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
      body: JSON.stringify({
        id,option
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
          )
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deletePollQuestion: async (id) => {
    return fetch(url + "deletePollQuestion?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  deletePollQuestionOption: async (id) => {
    return fetch(url + "deletePollQuestionOption?id="+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"basic "+await localStorage.getItem("token")

      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        if(data.msg=="Your session is not valid!"){
          await localStorage.setItem("token","")
          toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
        }
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  
getServices: async () => {
  return fetch(url + "getServices", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getService: async (id) => {
  return fetch(url + "getService?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceRating: async (id) => {
  return fetch(url + "getServiceRating?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceStats: async (id) => {
  return fetch(url + "getServiceStats?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
addService: async (title,body) => {
  return fetch(url + "addService", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
    body: JSON.stringify({
      title,body
    }),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
        )
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
deleteService: async (id) => {
  return fetch(url + "deleteService?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
addQuestionToService: async (id,question) => {
  return fetch(url + "addQuestionToService", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
    body: JSON.stringify({
      id,question
    }),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
        )
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceQuestions: async (id) => {
  return fetch(url + "getServiceQuestions?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
getServiceQuestionOptions: async (id) => {
  return fetch(url + "getServiceQuestionOptions?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
addOptionToServiceQuestion: async (id,option) => {
  return fetch(url + "addOptionToServiceQuestion", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
    body: JSON.stringify({
      id,option
    }),
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى"
        )
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
deleteServiceQuestion: async (id) => {
  return fetch(url + "deleteServiceQuestion?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
deleteServiceQuestionOption: async (id) => {
  return fetch(url + "deleteServiceQuestionOption?id="+id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"basic "+await localStorage.getItem("token")

    },
  })
    .then((response) => response.json())
    .then(async (data) => {
      if(data.msg=="Your session is not valid!"){
        await localStorage.setItem("token","")
        toast.error("انتهت الجلسة, الرجاء تسجيل الدخول مرة اخرى")
      }
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
},
};
