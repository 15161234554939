// Home.js
import { Dialog, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import Apis from './Apis';
import { confirmDialog } from 'primereact/confirmdialog';
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea';

const OrderHistory = ({id}) => {
const [visible,setVisible]=useState(false)
const [data,setData]=useState([])
const [inputData,setInputData]=useState("")
const getOrderHistory = async () => {
  try {
    const response = await Apis.getOrderHistory(id);
    if (response.status === 'success') {
      setData(response.data);
      console.log(response.data)
    }
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};
const formatDateTime=(dateString)=>{
  const date = new Date(dateString);
  
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  
  const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
  return formattedDate
    }

    function getSuggestions( key) {
      const values = data.map(obj => obj[key]);
      return [...new Set(values)];
  }
  useEffect(() => {
    getOrderHistory();
  }, []);
  useEffect(() => {
    getOrderHistory();
  }, [visible]);


  return (
    <>
        <Button
          onClick={async () => {
            setVisible(true)
          }}
          style={{       fontSize:12,
            marginRight:10 }}
        >
         السجل
        </Button>
     <Dialog
      open={visible}
      onClose={() => setVisible(false)}
      fullWidth
      maxWidth="sm"
          dir='rtl'
    >
       <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.3,
        },
      }}
    >
<div style={{
  display:"flex",
  flexDirection:"row"
}}>
      <Button
     variant='primary'
     style={{
       marginLeft: 'auto',
       display: 'block',
       marginRight:10
     }}
     onClick={async () => {
      const response =await Apis.addHistory(id,inputData)
      setInputData("")
      getOrderHistory()
     }}
   >
 اضافة 
   </Button>

   <InputTextarea value={inputData} onChange={(e) => setInputData(e.target.value)} autoResize  rows={1} cols={30} style={{
    flex:1,
    marginRight:10
   }}/>

</div>

   
      {
        data.map((history)=>
              <TimelineItem>
        <TimelineOppositeContent color="textSecondary">
         {formatDateTime(history.dateadded)}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color='success'/>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{
          textAlign:"right"
        }}>{history.body}</TimelineContent>
      <Button
     variant='danger'
     style={{
       marginLeft: 'auto',
       display: 'block',
       marginTop:10,
       marginBottom:10,
       marginRight:10
     }}
     onClick={async () => {
      const accept = async() => {

        const response=await Apis.deleteHistory(history.id)
        getOrderHistory()
       
      }

    const reject = () => {
        
    }
      confirmDialog({
        message: 'هل أنت متأكد أنك تريد حذف هذا العنصر؟',
        header: 'تأكيد الحذف',
        acceptClassName: 'p-button-danger',
        acceptLabel:"نعم",
        rejectLabel:"لا",
        style:{direction:"rtl"},
        accept,
        reject
    });
     }}
   >
 حذف 
   </Button>
      </TimelineItem>
        
        )
      }

      
    </Timeline>
    </Dialog>
    </>
 
  );
};

export default OrderHistory;
