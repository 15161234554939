import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import Apis from './Apis';
// Assume Apis.getPollQuestions, Apis.addQuestionToPoll, and Apis.getPollQuestionOptions are defined somewhere in your code.

import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { confirmDialog } from 'primereact/confirmdialog';
import { isMobile } from 'react-device-detect';
// ... (your existing imports)

const PollQuestionOptions = ({ id }) => {
    const [options, setOptions] = useState([]);
    const [isDialogVisible, setDialogVisible] = useState(false);
    const [newOption, setNewOption] = useState('');
  
    useEffect(() => {
      // Fetch questions initially
      getOptions();
    }, []);
  
    const getOptions = async () => {
      const response = await Apis.getPollQuestionOptions(id);
      if (response.status === 'success') {
        setOptions(response.data);
      }
    };
  
    const handleAddOption = async () => {
      const response = await Apis.addOptionToPollQuestion(id, newOption);
      if (response.status === 'success') {
        // Reload questions
        getOptions();
        setDialogVisible(false);
        setNewOption('');
      } else {
        toast.error('حدث خطأ، لم يتم إضافة السؤال');
      }
    };
  
    return (
      <div style={{ direction: 'rtl' }}>
        {options.map((option, index) => (
            <div>
                <div key={option.id} style={{
 display:"flex",
 flexDirection:"row"
                }}>
            <input type="radio" name="pollOption" value={option.optiontext} disabled/>
            <label style={{
                marginRight:"10px",
                marginLeft:"30px",
                marginBottom:"15px"
            }}>{option.optiontext}</label>
            <a variant="danger" style={{
                fontSize:"16px",
                color:"red",
                cursor:"pointer",
                fontWeight:"bold"
            }} onClick={() => {
                const accept = async () => {
                  const response = await Apis.deletePollQuestionOption(option.id);
                  if (response.status === 'success') {
                    toast.success('تم حذف الخيار بنجاح');
                    getOptions();
                  } else {
                    toast.error('حدث خطأ, لم يتم حذف الخيار');
                  }
                };
      
                const reject = () => {};
                confirmDialog({
                  message: 'هل أنت متأكد انك تريد حذف هذا الخيار ؟',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  rejectLabel: 'لا',
                  acceptLabel: 'نعم',
                  accept,
                  reject,
                });
              
              }  }>
       حذف 
      </a>
          </div> 
          {index === options.length - 1 && (
               <a style={{
                color:"#1976D2",
                fontWeight:"bold",
                cursor:"pointer"
              }} onClick={() => setDialogVisible(true)}>
                إضافة خيار
              </a>
            )}
                </div>
         
        ))}
  
  {options.length==0&&(

<a style={{
    color:"#1976D2",
    fontWeight:"bold",
    cursor:"pointer"
  }} onClick={() => setDialogVisible(true)}>
    إضافة خيار
  </a>
  )}
       <Dialog
        visible={isDialogVisible}
        style={{
            direction:"rtl",
            width:"90%"
        }}
          onHide={() => setDialogVisible(false)}
          header="إضافة خيار"
          footer={
            <div>
             <Button variant="primary" onClick={handleAddOption}>
              اضافة
            </Button>
            <Button variant="secondary" style={{
                marginRight:"20px"
            }} onClick={() => setDialogVisible(false)}>
              الغاء
            </Button>
           
          </div>
          }
        >
         <label style={{
            width:isMobile?"20%":"10%"
        }}>الخيار: </label>
        <input type="text" style={{
            width:isMobile?"80%":"90%"
        }} value={newOption} onChange={(e) => setNewOption(e.target.value)} />
      </Dialog>
      </div>
    );
  };
  
  export default PollQuestionOptions;
  