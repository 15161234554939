import React, { useState, useEffect } from "react";
import axios from "axios";
import fileDownload from 'js-file-download'
import Apis from "./Apis";

const ChatAttachment = ({ data }) => {
  const [fileSize, setFileSize] = useState(null);

  useEffect(() => {
    const getFileSize = async () => {
      try {
        const headRequest = await axios.head(data.url);
        const contentLength = headRequest.headers["content-length"];
        const fileSizeInBytes = contentLength;
        setFileSize(formatFileSize(fileSizeInBytes));
      } catch (error) {
        console.error("Error fetching file size:", error);
      }
    };

    getFileSize();
  }, [data.url]);

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("ar-EG", options);
  };
 const handleDownload = (url, filename) => {
  console.log(filename,url);
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }
   
  return (
    <div className="file-card rtl" style={{
      maxWidth:300,
      marginLeft:data.position=="left"?"0px":"auto",
      marginTop:20
    }}>
      <div className="details">
        <h3 style={{
            overflow:"hidden"
        }}>{data.name}</h3>
        <p>تاريخ الإضافة: {formatDate(data.dateadded)}</p>
        <p>حجم الملف: {fileSize}</p>
        <a style={{
            cursor:"pointer"
        }} onClick={()=>{
            handleDownload(data.url,data.name)
        }} className="download-button">
  تحميل
</a>

      </div>
    </div>
  );
};

export default ChatAttachment;
