// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Container, Button, CssBaseline } from '@mui/material';
import Home from './Home';
import Users from './Users';
import MiniDrawer from './Sidebar';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import 'primereact/resources/primereact.css';
import { PrimeReactProvider } from 'primereact/api';
import 'primeflex/primeflex.css';  
import 'primeicons/primeicons.css';

import 'primereact/resources/themes/viva-light/theme.css';
import NewsComments from './NewsComments';
import Events from './Events';
import EventsComments from './EventComments';
import Polls from './Polls';
import EditPoll from './EditPoll';
import PollStats from './PollStats';
import Services from './Services';
import EditService from './EditService';
import ServiceStats from './ServicesStats';

import LoginPage from "./Auth/LoginPage";
import Shippings from './Shippings';
import UserAttachments from './UserAttachments';
import OrderAttachments from './OrderAttachments';
import Chat from './Chat';
import UpdatePassword from './UpdatePassword';
import Dictionary from './Dictionary';

function App() {


  return (
    <PrimeReactProvider>
  <Router>
      <CssBaseline />
      <Container style={{
        maxWidth:3000
      }}>
    
    <ToastContainer position="bottom-right"
    rtl={true}
    draggable
      
/>

  <ConfirmDialog/>


        <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/users" element={<MiniDrawer Page={Users}/>} />
        <Route path="/users/attachments/:userId" element={<MiniDrawer Page={UserAttachments}/>} />
        <Route path="/shippings" element={<MiniDrawer Page={Shippings}/>} />
        <Route path="/shippings/attachments/:orderId" element={<MiniDrawer Page={OrderAttachments}/>} />
        <Route path="/events" element={<MiniDrawer Page={Events}/>} />
        <Route path="/eventscomments/:id" element={<MiniDrawer Page={EventsComments}/>} />
        <Route path="/polls" element={<MiniDrawer Page={Polls}/>} />
        <Route path="/polledit/:id" element={<MiniDrawer Page={EditPoll}/>} />
        <Route path="/pollstats/:id" element={<MiniDrawer Page={PollStats}/>} />
        <Route path="/updatepassword" element={<MiniDrawer Page={UpdatePassword}/>} />
        <Route path="/dictionary" element={<MiniDrawer Page={Dictionary}/>} />
        <Route path="/serviceedit/:id" element={<MiniDrawer Page={EditService}/>} />
        <Route path="/servicestats/:id" element={<MiniDrawer Page={ServiceStats}/>} />
        <Route path="/chat/:userId" element={<MiniDrawer Page={Chat}/>} />

          </Routes>
   
      </Container>
    </Router>
    </PrimeReactProvider>
  
  );
}

export default App;
