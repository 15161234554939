import React,{useState,useEffect,useRef} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Home from './Home';
import { isMobile } from 'react-device-detect';
import { ElectricalServices, Event, Poll } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import GroupIcon from '@mui/icons-material/Group';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MenuBookIcon from '@mui/icons-material/MenuBook';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginRight: drawerWidth, // swapped marginLeft with marginRight
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer({Page}) {
  const theme = useTheme();
  theme.direction = "rtl"; // added this line
  const [open, setOpen] = React.useState(isMobile?false:true);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [maxWidth, setMaxWidth] = React.useState(null);
  const referenceItemRef = useRef(null);
  const targetItemRef = useRef(null);

  useEffect(() => {
    // Function to update maximum width
    const updateMaxWidth = () => {
      if (referenceItemRef.current) {
        const referenceWidth = referenceItemRef.current.offsetWidth;
        setMaxWidth(referenceWidth);
      }
    };

    // Initially set the maximum width
    updateMaxWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateMaxWidth);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', updateMaxWidth);
    };
  }, []);
  return (
    <Box sx={{ display: 'flex',flexDirection:"row-reverse" }}>
      <AppBar position="fixed" open={open} dir='rtl' style={{
        backgroundColor:"#74bb9b"
      }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: 5, // swapped marginRight with marginLeft
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            مجموعة تيا للشحن الدولي
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} anchor='right'>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{
            direction:"rtl"
        }}>
           <Link to={"/users"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"المستخدمين"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <GroupIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"المستخدمين"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/shippings"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"الشحنات"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <LocalShippingIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"الشحنات"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
        
            <Link to={"/updatepassword"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"تغيير كلمة المرور"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <LockOpenIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"تغيير كلمة المرور"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>
            <Link to={"/dictionary"} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"القاموس"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                <MenuBookIcon /> 
                                </ListItemIcon>
                <ListItemText primary={"القاموس"} style={{
                    textAlign:"right"
                }}/>
              </ListItemButton>
            </ListItem>
            </Link>

            <a onClick={()=>{
              localStorage.removeItem("token")
              navigate("/")
            }} style={{
                color:"#000",
                textDecoration:"none"
            }}> 
              <ListItem key={"تسجيل الخروج"}>
              <ListItemButton style={{
                paddingRight:"0px"
              }}>
                <ListItemIcon>
                                </ListItemIcon>
                <ListItemText primary={"تسجيل الخروج"} style={{
                    textAlign:"right",
                    color:"red"
                }}/>
              </ListItemButton>
            </ListItem>
            </a>
        </List>
        <Divider />
    
      </Drawer>
      <Box component="main" ref={referenceItemRef} sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      <Page maxWidth={maxWidth}/>
      </Box>
    </Box>
  );
}
