import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import 'primeicons/primeicons.css';

const Events = () => {
  const [eventsData, setEventsData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [addImageURL, setAddImageUrl] = useState('');
  const [title, setTitle] = useState('');
  const [textBody, setTextBody] = useState('');

  const onTemplateUpload = (e) => {
    setAddImageUrl(JSON.parse(e.xhr.response).url);
  };

  const getEvents = async () => {
    try {
      const response = await Apis.getEvents();
      if (response.status === 'success') {
        setEventsData(response.data);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'title',
        header: 'العنوان',
        accessorKey: 'title',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.title}</div>
        ),
      },
      {
        id: 'bodytext',
        header: 'نص الفعالية',
        accessorKey: 'bodytext',
        Cell: ({ row }) => {
          const [showMore, setShowMore] = useState(false);

          const toggleShowMore = () => {
            setShowMore(!showMore);
          };

          return (
            <div className="text-end fw-bolder">
              <div
                style={{
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  WebkitLineClamp: showMore ? 'unset' : 2,
                  lineHeight: '1.5em',
                }}
              >
                {row.original.bodytext}
              </div>
              {row.original.bodytext.length > 100 && (
                <a
                  onClick={toggleShowMore}
                  style={{
                    color: '#3A7BBF',
                    cursor: 'pointer',
                  }}
                >
                  {showMore ? 'تصغير' : 'عرض المزيد'}
                </a>
              )}
            </div>
          );
        },
      },
      {
        id: 'image',
        header: 'الصورة',
        accessorKey: 'image',
        Cell: ({ row }) => (
          <a href={row.original.image} target="_blank">
            <img
              src={row.original.image}
              alt={`Image ${row.original.id}`}
              style={{ width: 100, height: 100 }}
            />
          </a>
        ),
      },
      {
        id: 'delete',
        header: '',
        Cell: ({ row }) => {
          const accept = async () => {
            const response = await Apis.deleteEvent(row.original.id);
            if (response.status === 'success') {
              toast.success('تم حذف الفعالية بنجاح');
              getEvents();
            } else {
              toast.error('حدث خطأ, لم يتم حذف الفعالية');
            }
          };

          const reject = () => {};

          return (
            <Button
              variant="danger"
              onClick={() => {
                confirmDialog({
                  message: 'هل أنت متأكد انك تريد حذف هذا الفعالية؟',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  rejectLabel: 'لا',
                  acceptLabel: 'نعم',
                  accept,
                  reject,
                });
              }}
            >
              حذف
            </Button>
          );
        },
      },
      {
        id: 'comments',
        header: '',
        Cell: ({ row }) => (
          <Button variant="primary">
            <Link
              to={`/eventscomments/${row.original.id}`}
              style={{
                color: '#fff',
                textDecoration: 'none',
              }}
            >
              التعليقات
            </Link>
          </Button>
        ),
      },
    ];

    return (
      <MaterialReactTable
        data={eventsData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
      />
    );
  };

  const renderCard = () => {
    return (
      <div className="card-deck">
        {eventsData.map((eventItem) => (
          <Card key={eventItem.id} style={{ marginBottom: '20px' }}>
            <Card.Img variant="top" src={eventItem.image} />
            <Card.Body>
              <Card.Title>{eventItem.title}</Card.Title>
              <Card.Text>{eventItem.bodytext}</Card.Text>
              <Link to={`/eventscomments/${eventItem.id}`} className="btn btn-primary">
                التعليقات
              </Link>
            </Card.Body>
            <Card.Footer>
              <small className="text-muted">
                <Button
                  variant="danger"
                  onClick={() => {
                    const accept = async () => {
                      const response = await Apis.deleteEvent(eventItem.id);
                      if (response.status === 'success') {
                        toast.success('تم حذف الفعالية بنجاح');
                        getEvents();
                      } else {
                        toast.error('حدث خطأ, لم يتم حذف الفعالية');
                      }
                    };
          
                    const reject = () => {};
                    confirmDialog({
                      message: 'هل أنت متأكد انك تريد حذف هذه الفعالية؟',
                      header: 'تأكيد الحذف',
                      acceptClassName: 'p-button-danger',
                      rejectLabel: 'لا',
                      acceptLabel: 'نعم',
                      accept,
                      reject,
                    });
                  
                  }}
                >
                  حذف
                </Button>
              </small>
            </Card.Footer>
          </Card>
        ))}
      </div>
    );
  };

  const handleDelete = async (id) => {
    const response = await Apis.deleteEvent(id);
    if (response.status === 'success') {
      toast.success('تم حذف الفعالية بنجاح');
      getEvents();
    } else {
      toast.error('حدث خطأ, لم يتم حذف الفعالية');
    }
  };

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>الفعاليات</h1>

          <div>
            <Button onClick={() => setVisible(true)}>إضافة فعالية جديدة</Button>
          </div>
        </div>

        {/* Render either table or card layout based on screen size */}
        {window.innerWidth > 768 ? renderTable() : renderCard()}

        <Dialog
          header="إضافة فعالية"
          visible={visible}
          style={{ width: '90vw', direction: 'rtl' }}
          onHide={() => setVisible(false)}
        >
          {addImageURL && (
            <img
              src={addImageURL}
              style={{
                width: 200,
                height: 200,
                marginLeft: 'auto',
                marginRight: 'auto',
                display: 'block',
                marginBottom: 20,
                borderRadius: 20,
              }}
              alt="Added Image"
            />
          )}

          <FileUpload
            style={{
              direction: 'ltr',
              justifyContent: 'center',
              display: 'flex',
            }}
            mode="basic"
            name="file"
            url="https://api.tiashipping.com/api/upload"
            accept="image/*"
            maxFileSize={1000000000}
            onUpload={onTemplateUpload}
            auto
            chooseLabel="صورة الفعالية"
          />

          <p style={{ fontWeight: 'bold', marginTop: 20 }}>عنوان الفعالية</p>
          <InputText
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: '100%' }}
          />

          <p style={{ fontWeight: 'bold', marginTop: 20 }}>نص الفعالية</p>
          <InputTextarea
            value={textBody}
            onChange={(e) => setTextBody(e.target.value)}
            style={{ width: '100%' }}
          />

          <Button
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              display: 'block',
            }}
            onClick={async () => {
              const response = await Apis.addEvent(title, textBody, addImageURL);
              if (response.status === 'success') {
                toast.success('تم إضافة الفعالية بنجاح');
                getEvents();
                setVisible(false);
              } else {
                toast.error('حدث خطأ, لم يتم إضافة الفعالية');
                getEvents();
                setVisible(false);
              }
            }}
          >
            إضافة
          </Button>
        </Dialog>
      </div>
    </div>
  );
};

export default Events;
