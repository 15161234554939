import React, { useState, useEffect, useRef } from 'react';
import { MessageList, Input, MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import Apis from './Apis';
import Box from '@mui/material/Box';
import ChatAttachment from './ChatAttachment';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';

export default function ChatScreen({ maxWidth }) {
  const [messages, setMessages] = useState([]);
  const chatRef = useRef(null);
  const msgRef = useRef(null);
  const socketRef = useRef(null);
  const fileRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileData, setSelectedFileData] = useState("");
  const [uploading, setUploading] = useState(false); // State to track file upload
  let { userId } = useParams();
  const [msg,setMsg]=useState('')
  const urlParams = new URLSearchParams(window.location.search);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedFile({
            file,
            preview: reader.result,
          });
        };
        reader.readAsDataURL(file);

   const filedata=uploadFile(file)
   console.log(filedata)
  
    }
  };

  const uploadFile = async (file) => {
    try {
      setUploading(true); // Set uploading state to true while uploading
      const formData = new FormData();
      formData.append('file', file);
      console.log(file)
      const response = await fetch('https://api.tiashipping.com/api/upload', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      console.log('Upload Response:', data);
      setSelectedFileData({
        name:data.name,
        url:data.url
       })
      toast.success('File uploaded successfully');
      return data;
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setUploading(false); // Set uploading state to false after upload completes
    }
  };
  useEffect(() => {
    const fetchChatMessages = async () => {
      try {
        const response = await Apis.getChat(userId);
        const formattedMessages = response.data.map((message) => {
          if (message.file) {
            // If message has a file, separate file from text
            return {
              position: message.sender === 0 ? 'right' : 'left',
              type: 'file',
              data:{
                uri: message.file,
                status: {
                  click: false,
                  loading: 0,
                },
              },
              text: message.filename,
              date: new Date(message.dateadded),
              onClick:()=>{
                console.log('You clicked on the file!')
              }
            };
          } else {
            return {
              position: message.sender === 0 ? 'right' : 'left',
              type: 'text',
              text: message.message,
              date: new Date(message.dateadded),
            };
          }
        });
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching chat messages:', error);
      }
    };

    fetchChatMessages();
  }, []);
  useEffect(() => {
    // Connect to Socket.IO server
    socketRef.current = io('https://api.tiashipping.com',{
        query: { token: localStorage.getItem("token") }, // Replace 'YOUR_TOKEN_HERE' with the actual token
      }); // Replace with your Socket.IO server URL

    // Listen for new message events
    socketRef.current.on('message', (newMessage) => {
      console.log(newMessage)
      const id=newMessage.id
      newMessage=newMessage.data
        newMessage.filename=newMessage.fileName
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      socketRef.current.emit('seemessage', JSON.stringify({
        id
      }));
   
    });

    return () => {
      // Clean up Socket.IO connection
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = (text) => {
    console.log(selectedFileData)
    const newMessage = {
        sender:0,
    user:userId,
      message: text,
        file:selectedFileData?.url||"",
        fileName:selectedFileData?.name||"",
        filename:selectedFileData?.name||"",
        dateadded:new Date()
    };
    // Emit message to the server
    socketRef.current.emit('adminmessage', JSON.stringify(newMessage));
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setSelectedFile(null)
    setSelectedFileData(null)
    fileRef.current.value=null
  };

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollIntoView();
    }
  };
  function formatDate(date) {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const dd = String(date.getDate()).padStart(2, '0');
    const hh = String(date.getHours()).padStart(2, '0');
    const min = String(date.getMinutes()).padStart(2, '0');
  
    return `${yyyy}-${mm}-${dd} ${hh}:${min}`;
  }
  return (
    <div>

<h1 style={{
        textAlign:"right"
      }}>
        {urlParams.get('name')}
      </h1>

      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', maxWidth: maxWidth }}>
      <Box sx={{ flex: 1, overflowY: 'auto' }}>
        {messages.map((message, index) => (
          <React.Fragment key={index}>
            {message.message && (
              <MessageBox
                position={message.sender === 0 ? 'right' : 'left'}
                type={'text'}
                text={message.message}
                date={new Date(message.dateadded)}
                dateString={formatDate(new Date(message.dateadded))}
              />
            )}
            {message.file && (
              <ChatAttachment
                data={{
                  url: message.file,
                  name: message.filename,
                  dateadded: message.dateadded,
                  position: message.sender === 0 ? 'right' : 'left',
                }}
              />
            )}
          </React.Fragment>
        ))}
        <div ref={chatRef}></div>
      </Box>
      <div style={{ position: 'sticky', bottom: 0 }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="file" onChange={handleFileChange} style={{ marginRight: '10px' }} ref={fileRef}/>
          {selectedFile && (
            <div style={{ marginRight: '10px' }}>
              <img src={selectedFile.preview} alt="Preview" style={{ maxHeight: '50px', maxWidth: '50px', marginRight: '5px' }} />
              <span>{selectedFile.name}</span>
            </div>
          )}
     
        </div>
        <Input
          placeholder="اكتب رسالتك هنا"
          defaultValue=""
          multiline={true}
          rightButtons={
            <a
              style={{
                cursor: 'pointer',
              }}
              onClick={(event) => {
                // Send message when button is clicked
                handleSendMessage(msg);
                setMsg("")
                msgRef.current.value=""
              }}
              className="download-button"
            >
              ارسال
            </a>
          }
          referance={msgRef}
          value={msg}
          onChange={(e)=>{setMsg(e.target.value)}}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSendMessage(event.target.value);
              event.target.value = '';
            }
          }}
        />
      </div>
    </Box>
    </div>
    
  );
}
