import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from '@mui/material';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import 'primeicons/primeicons.css';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import "react-datepicker/dist/react-datepicker.css";
import OrderHistory from './OrderHistory';
import { useNavigate } from "react-router-dom";

const Shippings = () => {
  const [ordersData, setOrdersData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);
  const [goToPage, setGoToPage] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem("currentPage")) || 0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [searchTerm, setSearchTerm] = useState(sessionStorage.getItem('searchTerm') || '');

  useEffect(() => {
    console.log(pagination);
    if (goToPage) {
      setPagination({ pageIndex: currentPage, pageSize: 10 });
      setGoToPage(false);
    }
    setCurrentPage(pagination.pageIndex);
    localStorage.setItem("currentPage", pagination.pageIndex);
  }, [pagination]);

  const formateDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  function getSuggestions(key) {
    const values = ordersData.map(obj => obj[key]);
    return [...new Set(values)];
  }

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const [editExpectedShipDate, setEditExpectedShipDate] = useState(new Date().getTime() + 43200000);
  const [editExpectedDelivery, setEditExpectedDelivery] = useState(new Date().getTime() + 43200000);
  const [EditUploadDate, setEditUploadDate] = useState(new Date().getTime() + 43200000);
  const [editOtherCost, setEditOtherCost] = useState('');
  const [editProductsCost, setEditProductsCost] = useState('');
  const [editShippingCost, setEditShippingCost] = useState('');
  const [editOrderType, setEditOrderType] = useState('');
  const [editOrderSize, setEditOrderSize] = useState('');
  const [editShippingTo, setEditShippingTo] = useState('');
  const [editShippingFrom, setEditShippingFrom] = useState('');
  const [editTrackingNum, setEditTrackingNum] = useState('');

  const [ExpectedShipDate, setExpectedShipDate] = useState(null);
  const [ExpectedDelivery, setExpectedDelivery] = useState(null);
  const [OtherCost, setOtherCost] = useState('');
  const [ProductsCost, setProductsCost] = useState('');
  const [ShippingCost, setShippingCost] = useState('');
  const [OrderType, setOrderType] = useState('');
  const [OrderSize, setOrderSize] = useState('');
  const [ShippingTo, setShippingTo] = useState('');
  const [ShippingFrom, setShippingFrom] = useState('');
  const [TrackingNum, setTrackingNum] = useState('');
  const [UploadDate, setUploadDate] = useState(null);

  const [editMode, setEditMode] = useState(false);
  const [OrderHistoryVisible, setOrderHistoryVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const [selectedUser, setSelectedUser] = useState({
    name: "",
    code: 0
  });
  const [usersData, setUsersData] = useState([]);

  const getUsers = async () => {
    try {
      setLoading(true);
      const response = await Apis.getUsers();
      setLoading(false);
      if (response.status === 'success') {
        const users = response.data.map(user => ({
          name: user.name + " - " + user.idnum,
          code: user.idnum,
          id: user.id
        }));
        setUsersData(users);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const getOrders = async () => {
    setCurrentPage(pagination.pageIndex);
    localStorage.setItem("currentPage", pagination.pageIndex);
    setGoToPage(true);
    try {
      setLoading(true);
      const response = await Apis.getOrders();
      setLoading(false);
      if (response.status === 'success') {
        setOrdersData(response.data);
        setPagination({
          pageIndex: currentPage,
          pageSize: 10
        });
      }
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  useEffect(() => {
    getOrders();
    getUsers();
  }, []);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    sessionStorage.setItem('searchTerm', value);
  };

  const renderTable = () => {
    var columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        maxSize: 1,

        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.id}</div>
        ),
      },
      {
        id: 'username',
        header: 'اسم المستخدم',
        accessorKey: 'username',
        filterVariant: 'autocomplete',
        maxSize: 1,
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{row.original.username}</div>
        ),
      },
      {
        id: 'trackingnum',
        header: 'رقم التتبع',
        accessorKey: 'trackingnum',
        filterVariant: 'autocomplete',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("trackingnum")}
              value={editTrackingNum}
              onInputChange={(event, newInputValue) => {
                setEditTrackingNum(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.trackingnum}</div>
        ),
      },
      {
        id: 'shippingfrom',
        header: 'الشحن من',
        accessorKey: 'shippingfrom',
        filterVariant: 'autocomplete',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("shippingfrom")}
              value={editShippingFrom}
              onInputChange={(event, newInputValue) => {
                setEditShippingFrom(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.shippingfrom}</div>
        ),
      },
      {
        id: 'shippingto',
        header: 'الشحن الى',
        accessorKey: 'shippingto',
        filterVariant: 'autocomplete',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("shippingto")}
              value={editShippingTo}
              onInputChange={(event, newInputValue) => {
                setEditShippingTo(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.shippingto}</div>
        ),
      },
      {
        id: 'ordertype',
        header: 'نوع الشحنة',
        accessorKey: 'ordertype',
        filterVariant: 'select',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("ordertype")}
              value={editOrderType}
              onInputChange={(event, newInputValue) => {
                setEditOrderType(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.ordertype}</div>
        ),
      },
      {
        id: 'ordersize',
        header: 'حجم الشحنة',
        accessorKey: 'ordersize',
        filterVariant: 'select',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("ordersize")}
              value={editOrderSize}
              onInputChange={(event, newInputValue) => {
                setEditOrderSize(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.ordersize}</div>
        ),
      },
      {
        id: 'uploaddate',
        header: 'تاريخ التحميل',
        accessorKey: 'uploaddate',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <div className="card flex justify-content-center">
              <Calendar value={new Date(EditUploadDate)} onChange={(e) => {
                console.log(e.value);
                setEditUploadDate(e.value.getTime() + 43200000);
              }} />
            </div> :
            <div className="text-end fw-bolder">{formateDate(row.original.uploaddate)}</div>
        ),
      },
      {
        id: 'shippingcost',
        header: 'تكاليف الشحن',
        accessorKey: 'shippingcost',
        filterVariant: 'range-slider',
        muiFilterSliderProps: {
          marks: true,
          min: 0,
          step: 100,
        },
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("shippingcost")}
              value={editShippingCost}
              onInputChange={(event, newInputValue) => {
                setEditShippingCost(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.shippingcost}</div>
        ),
      },
      {
        id: 'productscost',
        header: 'تكاليف المنتجات',
        accessorKey: 'productscost',
        maxSize: 1,
        filterVariant: 'range-slider',
        muiFilterSliderProps: {
          marks: true,
          min: 0,
          step: 100,
        },
        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("productscost")}
              value={editProductsCost}
              onInputChange={(event, newInputValue) => {
                setEditProductsCost(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.productscost}</div>
        ),
      },
      {
        id: 'othercost',
        header: 'تكاليف التخليص الجمركي',
        accessorKey: 'othercost',
        maxSize: 1,
        filterVariant: 'range-slider',
        muiFilterSliderProps: {
          marks: true,
          min: 0,
          step: 100,
        },
        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <Autocomplete
              freeSolo
              options={getSuggestions("othercost")}
              value={editOtherCost}
              onInputChange={(event, newInputValue) => {
                setEditOtherCost(newInputValue);
              }}
              renderInput={(params) => <TextField style={{
                textAlign: "right",
              }} {...params} />}
            /> :
            <div className="text-end fw-bolder">{row.original.othercost}</div>
        ),
      },
      {
        id: 'expecteddelivery',
        header: 'الوقت المتوقع للتسليم',
        accessorKey: 'expecteddelivery',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <div className="card flex justify-content-center">
              <Calendar value={new Date(editExpectedDelivery)} onChange={(e) => {
                console.log(e.value);
                setEditExpectedDelivery(e.value.getTime() + 43200000);
              }} />
            </div> :
            <div className="text-end fw-bolder">{formateDate(row.original.expecteddelivery)}</div>
        ),
      },
      {
        id: 'expectedshipdate',
        header: 'الوقت المتوقع للوصول للميناء',
        accessorKey: 'expectedshipdate',
        maxSize: 1,

        Cell: ({ row }) => (
          (editMode && selectedOrder == row.original.id) ?
            <div className="card flex justify-content-center">
              <Calendar value={new Date(editExpectedShipDate)} onChange={(e) => setEditExpectedShipDate(e.value.getTime() + 43200000)} />
            </div> :
            <div className="text-end fw-bolder">{formateDate(row.original.expectedshipdate)}</div>
        ),
      },
      {
        id: 'actions',
        header: '',
        accessorKey: '',

        Cell: ({ row }) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {(editMode && selectedOrder == row.original.id) ?
              <Button
                variant='warning'
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  fontSize: 12,
                }}
                onClick={async () => {
                  setEditMode(!editMode);
                  const response = await Apis.editOrder(editTrackingNum, editShippingFrom, editShippingTo, editOrderType, editOrderSize, editShippingCost, editProductsCost, editOtherCost, editExpectedDelivery, editExpectedShipDate, row.original.id, EditUploadDate);
                  setLoading(false);
                  if (response.status === 'success') {
                    toast.success(response.msg);
                    getOrders();
                  } else {
                    toast.error(response.msg);
                    getOrders();
                  }
                }}
              >
                حفظ
              </Button>
              :
              <Button
                variant='success'
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  fontSize: 12,
                }}
                onClick={async () => {
                  setEditMode(!editMode);
                  setSelectedOrder(row.original.id);
                  setEditExpectedShipDate(row.original.expectedshipdate);
                  setEditUploadDate(row.original.uploaddate);
                  setEditExpectedDelivery(row.original.expecteddelivery);
                  setEditOtherCost(row.original.othercost);
                  setEditProductsCost(row.original.productscost);
                  setEditShippingCost(row.original.shippingcost);
                  setEditOrderType(row.original.ordertype);
                  setEditOrderSize(row.original.ordersize);
                  setEditShippingTo(row.original.shippingto);
                  setEditShippingFrom(row.original.shippingfrom);
                  setEditTrackingNum(row.original.trackingnum);
                }}
              >
                تعديل
              </Button>
            }
            <Button
              variant='warning'
              style={{
                marginLeft: 'auto',
                display: 'block',
                fontSize: 12,
                marginRight: 10,
              }}
              onClick={async () => {
                navigate("/shippings/attachments/" + row.original.id + "?name=" + row.original.username);
              }}
            >
              المرفقات
            </Button>
            <OrderHistory id={row.original.id} />
            <Button
              variant='danger'
              style={{
                marginLeft: 'auto',
                display: 'block',
                fontSize: 12,
                marginRight: 10,
              }}
              onClick={async () => {
                const accept = () => {
                  handleDelete(row.original.id);
                }
                const reject = () => { }
                confirmDialog({
                  message: 'هل أنت متأكد أنك تريد حذف هذه الشحنة؟',
                  header: 'تأكيد الحذف',
                  acceptClassName: 'p-button-danger',
                  acceptLabel: "نعم",
                  rejectLabel: "لا",
                  style: { direction: "rtl" },
                  accept,
                  reject
                });
              }}
            >
              حذف
            </Button>
          </div>
        ),
      }
    ];

    return (
      <div style={{ maxWidth: screenWidth - 350 }}>
        <MaterialReactTable
          data={ordersData}
          enableFacetedValues
          columnFilterDisplayMode={"popover"}
          columns={columns}
          state={{ pagination, globalFilter: searchTerm }}
          onPaginationChange={setPagination}
          onGlobalFilterChange={(filter) => setSearchTerm(filter || '')}
          options={{
            search: false,
            paging: true,
            pageSize: 10,
          }}
          enableColumnFilterModes
          initialState={{
            density: "compact",
            pagination: {
              pageIndex: currentPage,
              pageSize: 10,
            },
          }}
        />
      </div>
    );
  };

  const handleDelete = async (id) => {
    setLoading(true);
    const response = await Apis.deleteOrder(id);
    setLoading(false);
    if (response.status === 'success') {
      toast.success('تم حذف الشحنة بنجاح');
      getOrders();
    } else {
      toast.error('حدث خطأ, لم يتم حذف الشحنة ');
    }
  };

  return (
    <div>
      <div style={{ maxWidth: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>الشحنات</h1>
          <div>
            <Button onClick={() => setVisible(true)}>إنشاء شحنة جديدة</Button>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <InputText
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="ابحث هنا..."
            style={{ width: '100%', direction: 'rtl' }}
          />
        </div>
        {renderTable()}
        <Dialog
          open={visible}
          onClose={() => setVisible(false)}
          fullWidth
          maxWidth="sm"
          style={{ direction: 'rtl' }}
        >
          <div style={{ padding: 20 }}>
            <h2 style={{ fontWeight: 'bold', marginBottom: 20 }}>إنشاء شحنة جديدة</h2>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>المستخدم</p>
              <div>
                <Dropdown filter value={selectedUser} onChange={(e) => setSelectedUser(e.value)} options={usersData} optionLabel="name" placeholder="المستخدم" className="w-full md:w-14rem" />
              </div>
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>رقم التتبع</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("trackingnum")}
                value={TrackingNum}
                onInputChange={(event, newInputValue) => {
                  setTrackingNum(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>الشحن من</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("shippingfrom")}
                value={ShippingFrom}
                onInputChange={(event, newInputValue) => {
                  setShippingFrom(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>الشحن الى</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("shippingto")}
                value={ShippingTo}
                onInputChange={(event, newInputValue) => {
                  setShippingTo(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>نوع الشحنة</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("ordertype")}
                value={OrderType}
                onInputChange={(event, newInputValue) => {
                  setOrderType(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>حجم الشحنة</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("ordersize")}
                value={OrderSize}
                onInputChange={(event, newInputValue) => {
                  setOrderSize(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>تكاليف الشحن</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("shippingcost")}
                value={ShippingCost}
                onInputChange={(event, newInputValue) => {
                  setShippingCost(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>تكاليف المنتجات</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("productscost")}
                value={ProductsCost}
                onInputChange={(event, newInputValue) => {
                  setProductsCost(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>تكاليف التخليص الجمركي</p>
              <Autocomplete
                freeSolo
                options={getSuggestions("othercost")}
                value={OtherCost}
                onInputChange={(event, newInputValue) => {
                  setOtherCost(newInputValue);
                }}
                renderInput={(params) => <TextField style={{
                  textAlign: "right",
                }} {...params} />}
              />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>تاريخ التحميل</p>
              <Calendar value={UploadDate ? new Date(UploadDate) : ""} onChange={(e) => {
                console.log(new Date(e.value).getTime());
                setUploadDate(new Date(e.value).getTime() + 43200000);
              }} panelStyle={{
                zIndex: 9999
              }} style={{
                zIndex: 99999
              }} />
            </div>
            <div>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>الوقت المتوقع للتسليم</p>
              <Calendar value={ExpectedDelivery ? new Date(ExpectedDelivery) : ""} onChange={(e) => {
                console.log(new Date(e.value).getTime());
                setExpectedDelivery(new Date(e.value).getTime() + 43200000);
              }} panelStyle={{
                zIndex: 9999
              }} style={{
                zIndex: 99999
              }} />
            </div>
            <div style={{
              zIndex: 999999999
            }}>
              <p style={{ fontWeight: 'bold', marginTop: 20 }}>الوقت المتوقع للوصول للميناء</p>
              <Calendar value={ExpectedShipDate ? new Date(ExpectedShipDate) : ""} onChange={(e) => setExpectedShipDate(e.value.getTime() + 43200000)} />
            </div>
            <Button
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                const response = await Apis.createOrder(
                  ExpectedShipDate,
                  ExpectedDelivery,
                  OtherCost,
                  ProductsCost,
                  ShippingCost,
                  OrderType,
                  OrderSize,
                  ShippingTo,
                  ShippingFrom,
                  TrackingNum,
                  selectedUser.id,
                  UploadDate
                );
                setLoading(false);
                if (response.status === "success") {
                  toast.success(response.msg);
                } else {
                  toast.error(response.msg);
                }
                getOrders();
                setVisible(false);
              }}
              style={{ marginTop: 20 }}
            >
              إنشاء شحنة
            </Button>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Shippings;
