import React, { useEffect, useRef, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';

import 'primeicons/primeicons.css';

const Dictionary = () => {
  const [dictionaryData, setDictionaryData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [idnum, setIdNum] = useState('');
  const [password, setPassword] = useState('');


  const [editname, setEditName] = useState('');
  const [editEnglish, setEditEnglish] = useState('');
  const [editmobile, setEditMobile] = useState('');
  const [editaddress, setEditAddress] = useState('');
  const [editArabic, setEditArabic] = useState('');
  const [editpassword, setEditPassword] = useState('');


  const [editMode,setEditMode]=useState(false)
  const [selectedWord,setSelectedWord]=useState(0)
  const [selectedWordName,setSelectedWordName]=useState("")

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedDictionary, setSelectedDictionary] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    // Add event listener to update screenWidth state whenever the window is resized
    window.addEventListener('resize', updateScreenWidth);

    // Remove event listener when the component unmounts to avoid memory leaks
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); // Empty dependency array ensures that this effect runs only once after the component mounts
  const generatePassword=(passwordLength)=>{
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var password = "";

 for (var i = 0; i <= passwordLength; i++) {
  var randomNumber = Math.floor(Math.random() * chars.length);
  password += chars.substring(randomNumber, randomNumber +1);
 }

 return password
  }


  const getDictionary = async () => {
    try {
      const response = await Apis.getDictionary();
      if (response.status === 'success') {
        console.log(response)
        setDictionaryData(response.data);
      }
    } catch (error) {
      console.error('Error fetching dictionary:', error);
    }
  };

  useEffect(() => {
    getDictionary();
  }, []);




  useEffect(() => {
    console.info({ rowSelection }); //read your managed row selection state
    // console.info(table.getState().rowSelection); //alternate way to get the row selection state
  }, [rowSelection]);

  const renderTable = () => {
    var columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
<div className="text-end fw-bolder">
  {row.original.id} 
</div>          
        ),
      },
      {
        id: 'arabic',
        header: 'العربية',
        accessorKey: 'arabic',
        Cell: ({ row }) => 

          (

            (editMode&&selectedWord==row.original.id)?   <InputText
            value={editArabic}
            onChange={(e) => setEditArabic(e.target.value)}
            style={{ width: '100%' }}
          />:
              <div className="text-end fw-bolder">{row.original.arabic}</div>
            
            
            


          )
        ,
      },
      {
        id: 'english',
        header: 'English',
        accessorKey: 'english',
        Cell: ({ row }) => 

          (

            (editMode&&selectedWord==row.original.id)?   <InputText
            value={editEnglish}
            onChange={(e) => setEditEnglish(e.target.value)}
            style={{ width: '100%' }}
          />:
              <div className="text-end fw-bolder">{row.original.english}</div>
            
            
            


          )
        ,
      },
      {
        id: 'actions',
        header: '',
        accessorKey: '',
  
        Cell: ({ row }) => (
          
          <div style={{
            display:"flex",
            flexDirection:"row"
          }}>


{
     (editMode&&selectedWord==row.original.id)?    <Button
     variant='warning'
     style={{
       marginLeft: 'auto',
       marginRight: 'auto',
       display: 'block',
       fontSize:12
     
     }}
     onClick={async () => {
     setEditMode(!editMode)
       const response=await Apis.editDictionary(editEnglish,editArabic,row.original.id)
       console.log(response)
       if (response.status === 'success') {
         toast.success(response.msg);
         getDictionary();
       } else {
         toast.error(response.msg);
         getDictionary();
       }
     }}
   >
 حفظ
   </Button>
:    <Button
     variant='success'
     style={{
       marginLeft: 'auto',
       marginRight: 'auto',
       display: 'block',
  fontSize:12

     }}
     onClick={async () => {
     setEditMode(!editMode)
     setSelectedWord(row.original.id)
     setEditEnglish(row.original.english)
     setEditArabic(row.original.arabic)
     }}
   >
 تعديل
   </Button>


}



            </div>

       
        ),
      }
      
    ];


    return (
      <div style={{ maxWidth: screenWidth-350 }}>
   <MaterialReactTable
        data={dictionaryData}
        columns={columns}
        options={{
          search: false,
          paging: false,
        }}
        initialState={{
          density:"compact"
        }}

      

      />

</div>
   
    );
  };


  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div
          style={{
            width: '100%',
            direction: 'rtl',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <h1>القاموس</h1>
  
        </div>
  
        {renderTable() }
  
        

      </div>
      <div>
    
    </div>
    </div>
  );
  
};

export default Dictionary;
