import React, { useEffect, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';

import 'primeicons/primeicons.css';
import FileCard from './FileCard';

const UserAttachments = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true); // Set initial loading state to true
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFile, setSelectedFile] = useState(null); // State to hold the selected file
  const [uploading, setUploading] = useState(false); // State to track file upload
  const navigate = useNavigate();
  let { userId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const getUserAttachments = async () => {
    try {
      setLoading(true); // Set loading state to true while fetching data
      const response = await Apis.getUserAttachments(userId);
      if (response.status === 'success') {
        console.log(response);
        setData(response.data);
        setFilteredData(response.data); // Initialize filtered data with all data
      }
    } catch (error) {
      console.error('Error fetching Attachments:', error);
    } finally {
      setLoading(false); // Set loading state to false after data fetching is done
    }
  };

  useEffect(() => {
    getUserAttachments();
  }, []);

  const formateDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  };

  // Function to filter data based on search query
  const filterData = () => {
    if (!searchQuery.trim()) {
      setFilteredData(data); // If search query is empty, display all data
    } else {
      const filtered = data.filter((file) =>
        file.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filtered); // Update filtered data based on search query
    }
  };

  // Handle search query change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    filterData();
  }, [searchQuery, data]); // Trigger filtering whenever searchQuery or data changes

  // Function to handle file selection
  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    uploadFile(event.target.files[0]);
  };

  // Function to upload selected file to the API
  const uploadFile = async (file) => {
    try {
      setUploading(true); // Set uploading state to true while uploading
      const formData = new FormData();
      formData.append('file', file);
      const response = await fetch('https://api.tiashipping.com/api/upload', {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      console.log('Upload Response:', data);
     const addresponse= await Apis.addUserAttachment(userId, data);
      // Refresh attachments after successful upload
      getUserAttachments();
      toast.success('File uploaded successfully');
      console.log(addresponse)
    } catch (error) {
      console.error('Error uploading file:', error);
      toast.error('Failed to upload file');
    } finally {
      setUploading(false); // Set uploading state to false after upload completes
    }
  };

  return (
    <div>

      <h1 style={{
        textAlign:"right"
      }}>
        {urlParams.get('name')}
      </h1>
      <div style={{
        direction:"rtl",
        marginBottom:20,
        display:"flex"
      }}>
        <InputText
          value={searchQuery}
          onChange={handleSearchInputChange}
          placeholder="ابحث عن اسم ملف"
        />
        {/* Input element for file selection */}
        <input type="file" onChange={handleFileSelect} style={{ display: 'none' }} id="fileInput" />
        <Button style={{
          marginRight:"auto"
        }} onClick={() => document.getElementById('fileInput').click()} disabled={uploading}>{uploading?"جار الرفع":"إضافة ملف"}</Button>
      </div>
      {loading ? ( // Render loading spinner while data is being fetched
        <div style={{ textAlign: 'center' }}>جار التحميل...</div>
      ) : (
        <div className="row" dir="rtl">
          {filteredData.map((file) => (
            <div className="col-sm-6 col-md-4 col-lg-3 " key={file.id}>
              <FileCard data={file} getFiles={getUserAttachments}/>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserAttachments;
