import React, { useEffect, useRef, useState } from 'react';
import Apis from './Apis';
import { toast } from 'react-toastify';
import { MaterialReactTable } from 'material-react-table';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Checkbox } from 'primereact/checkbox';

import 'primeicons/primeicons.css';

const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [idnum, setIdNum] = useState('');
  const [password, setPassword] = useState('');

  const [editname, setEditName] = useState('');
  const [editusername, setEditUsername] = useState('');
  const [editmobile, setEditMobile] = useState('');
  const [editaddress, setEditAddress] = useState('');
  const [editidnum, setEditIdNum] = useState('');
  const [editpassword, setEditPassword] = useState('');

  const [editMode, setEditMode] = useState(false);
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectedUserName, setSelectedUserName] = useState("");

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [searchTerm, setSearchTerm] = useState(sessionStorage.getItem('searchTermUsers') || '');

  const [goToPage, setGoToPage] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(parseInt(localStorage.getItem("currentPageUsers")) || 0);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    if (goToPage) {
      setPagination({ pageIndex: currentPage, pageSize: 10 });
      setGoToPage(false);
    }
    setCurrentPage(pagination.pageIndex);
    localStorage.setItem("currentPageUsers", pagination.pageIndex);
  }, [pagination]);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  const generatePassword = (passwordLength) => {
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#*ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var password = "";

    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }

    return password;
  }

  const getUsers = async () => {
    setCurrentPage(pagination.pageIndex);
    localStorage.setItem("currentPageUsers", pagination.pageIndex);
    setGoToPage(true);
    try {
      const response = await Apis.getUsers();
      if (response.status === 'success') {
        setUsersData(response.data);
        setPagination({
          pageIndex: currentPage,
          pageSize: 10
        });
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    console.info({ rowSelection });
  }, [rowSelection]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    sessionStorage.setItem('searchTermUsers', value);
  };

  const renderTable = () => {
    var columns = [
      {
        id: 'id',
        header: '#',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">
            {row.original.id}
            {row.original.unreadMessages !== 0 && (
              <span className="badge bg-primary ms-1" style={{ marginRight: 10, cursor: "pointer" }} onClick={() => { navigate("/chat/" + row.original.id); }}>
                {row.original.unreadMessages} رسالة جديدة
              </span>
            )}
          </div>
        ),
      },
      {
        id: 'idnum',
        header: 'الرقم التعريفي',
        accessorKey: 'idnum',
        Cell: ({ row }) => (
          (editMode && selectedUser == row.original.id) ?
            <InputText value={editidnum} onChange={(e) => setEditIdNum(e.target.value)} style={{ width: '100%' }} /> :
            <div className="text-end fw-bolder">{row.original.idnum}</div>
        ),
      },
      {
        id: 'username',
        header: 'Nickname',
        accessorKey: 'username',
        Cell: ({ row }) => (
          (editMode && selectedUser == row.original.id) ?
            <InputText value={editusername} onChange={(e) => setEditUsername(e.target.value)} style={{ width: '100%' }} /> :
            <div className="text-end fw-bolder">{row.original.username}</div>
        ),
      },
      {
        id: 'name',
        header: 'الاسم',
        accessorKey: 'name',
        Cell: ({ row }) => (
          (editMode && selectedUser == row.original.id) ?
            <InputText value={editname} onChange={(e) => setEditName(e.target.value)} style={{ width: '100%' }} /> :
            <div className="text-end fw-bolder">{row.original.name}</div>
        ),
      },
      {
        id: 'mobile',
        header: 'رقم الهاتف',
        accessorKey: 'mobile',
        Cell: ({ row }) => (
          (editMode && selectedUser == row.original.id) ?
            <InputText value={editmobile} onChange={(e) => setEditMobile(e.target.value)} style={{ width: '100%' }} /> :
            <div className="text-end fw-bolder">{row.original.mobile}</div>
        ),
      },
      {
        id: 'address',
        header: 'العنوان',
        accessorKey: 'address',
        Cell: ({ row }) => (
          (editMode && selectedUser == row.original.id) ?
            <InputText value={editaddress} onChange={(e) => setEditAddress(e.target.value)} style={{ width: '100%' }} /> :
            <div className="text-end fw-bolder">{row.original.address}</div>
        ),
      },
      {
        id: 'lastlogin',
        header: 'آخر تسجيل دخول',
        accessorKey: 'lastlogin',
        Cell: ({ row }) => (
          <div className="text-end fw-bolder">{formateDate(row.original.lastlogin)}</div>
        ),
      },
      {
        id: 'password',
        header: 'كلمة المرور',
        accessorKey: 'password',
        Cell: ({ row }) => (
          (editMode && selectedUser == row.original.id) ?
            <InputText value={editpassword} onChange={(e) => setEditPassword(e.target.value)} style={{ width: '100%' }} /> :
            <div className="text-end fw-bolder">********</div>
        ),
      },
      {
        id: 'actions',
        header: '',
        accessorKey: '',
        Cell: ({ row }) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {(editMode && selectedUser == row.original.id) ?
              <Button variant='warning' style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', fontSize: 12 }} onClick={async () => {
                setEditMode(!editMode);
                const response = await Apis.editUser(editname, editusername, editmobile, editpassword, editaddress, editidnum, row.original.id);
                if (response.status === 'success') {
                  toast.success(response.msg);
                  getUsers();
                } else {
                  toast.error(response.msg);
                  getUsers();
                }
              }}>
                حفظ
              </Button>
              :
              <Button variant='success' style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', fontSize: 12 }} onClick={async () => {
                setEditMode(!editMode);
                setSelectedUser(row.original.id);
                setEditName(row.original.name);
                setEditUsername(row.original.username);
                setEditMobile(row.original.mobile);
                setEditAddress(row.original.address);
                setEditIdNum(row.original.idnum);
                setEditPassword("");
              }}>
                تعديل
              </Button>
            }
            <Button variant='warning' style={{ marginLeft: 'auto', display: 'block', marginRight: 10, fontSize: 12 }} onClick={async () => {
              setNotificationVisible(true);
              setSelectedUser(row.original.id);
              setSelectedUserName(row.original.username);
            }}>
              ارسال اشعار
            </Button>
            <Button variant='success' style={{ marginLeft: 'auto', display: 'block', marginRight: 10, fontSize: 12 }} onClick={async () => {
              navigate("/users/attachments/" + row.original.id + "?name=" + row.original.username);
            }}>
              المرفقات
            </Button>
            <Button variant='success' style={{ marginLeft: 'auto', display: 'block', marginRight: 10, fontSize: 12 }} onClick={async () => {
              navigate("/chat/" + row.original.id + "?name=" + row.original.username);
            }}>
              المحادثة
            </Button>
            {row.original.blocked ?
              <Button variant='primary' style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginRight: 10, fontSize: 12 }} onClick={async () => {
                const res = await Apis.unBlockUser(row.original.id);
                getUsers();
              }}>
                فك الحظر
              </Button>
              :
              <Button variant='danger' style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginRight: 10, fontSize: 12 }} onClick={async () => {
                const res = await Apis.BlockUser(row.original.id);
                getUsers();
              }}>
                حظر
              </Button>
            }
          </div>
        ),
      }
    ];

    return (
      <div style={{ maxWidth: screenWidth - 350 }}>
        <MaterialReactTable
          data={usersData}
          columns={columns}
          options={{
            search: false,
            paging: false,
          }}
          initialState={{
            density: "compact",
            pagination: {
              pageIndex: currentPage,
              pageSize: 10
            },
          }}
          state={{ pagination, globalFilter: searchTerm }}
          onPaginationChange={setPagination}
          onGlobalFilterChange={(filter) => setSearchTerm(filter || '')}
        />
      </div>
    );
  };

  const formateDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formattedDate;
  }

  const handleDelete = async (id) => {
    const response = await Apis.deleteUsers(id);
    if (response.status === 'success') {
      toast.success('تم حذف المستخدم بنجاح');
      getUsers();
    } else {
      toast.error('حدث خطأ, لم يتم حذف المستخدم');
    }
  };

  const handleActionWithSelectedUsers = async () => {
    const response = await Apis.sendNotification(notificationTitle, notificationBody, selectedUsers);
    if (response.status === 'success') {
      toast.success("تم ارسال الاشعار بنجاح");
      setSelectedUsers([]);
      setShowPopup(false);
    } else {
      setSelectedUsers([]);
      setShowPopup(false);
    }
  };

  const handleSelectAll = () => {
    const allUserIds = usersData.map(user => user.id);
    if (selectedUsers.length === allUserIds.length)
      setSelectedUsers([]);
    else
      setSelectedUsers(allUserIds);
  };

  const filteredUsers = usersData.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.idnum.includes(searchTerm)
  );

  return (
    <div>
      <div style={{ width: '100%', direction: 'rtl' }}>
        <div style={{ width: '100%', direction: 'rtl', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <h1>المستخدمين</h1>
          <div>
            <Button onClick={() => setVisible(true)} style={{ marginLeft: 10, marginRight: 10 }}>إنشاء مستخدم جديد</Button>
            <Button onClick={() => setShowPopup(true)} style={{ marginLeft: 10, marginRight: 10 }}>ارسال اشعار الى عدة مستخدمين</Button>
          </div>
        </div>
        <div style={{ marginBottom: 20 }}>
          <InputText value={searchTerm} onChange={handleSearchChange} placeholder="ابحث هنا..." style={{ width: '100%', direction: 'rtl' }} />
        </div>
        {renderTable()}
        <Dialog header="إنشاء مستخدم جديد" visible={visible} style={{ width: '40vw', direction: 'rtl' }} onHide={() => setVisible(false)}>
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>الاسم الكامل</p>
          <InputText value={name} onChange={(e) => setName(e.target.value)} style={{ width: '100%' }} />
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>Nickname</p>
          <InputText value={username} onChange={(e) => setUsername(e.target.value)} style={{ width: '100%' }} />
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>الرقم التعريفي</p>
          <InputText value={idnum} onChange={(e) => setIdNum(e.target.value)} style={{ width: '100%' }} />
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>رقم الهاتف</p>
          <InputText value={mobile} onChange={(e) => setMobile(e.target.value)} style={{ width: '100%' }} />
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>العنوان</p>
          <InputText value={address} onChange={(e) => setAddress(e.target.value)} style={{ width: '100%' }} />
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>كلمة المرور</p>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <InputText value={password} onChange={(e) => setPassword(e.target.value)} style={{ width: '100%' }} />
            <Button style={{ marginLeft: 'auto', display: 'block', marginRight: 50 }} onClick={async () => {
              setPassword(generatePassword(8));
            }}>
              إنشاء كلمة مرور
            </Button>
          </div>
          <p style={{ fontWeight: 'bold', marginTop: 10, fontSize: 12, color: "red" }}>* الرجاء نسخ كلمة المرور قبل الحفظ</p>
          <Button style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: 20 }} onClick={async () => {
            const response = await Apis.addUser(name, username, mobile, password, address, idnum);
            if (response.status === 'success') {
              toast.success(response.msg);
              getUsers();
              setVisible(false);
            } else {
              toast.error(response.msg);
              getUsers();
            }
          }}>
            إنشاء مستخدم
          </Button>
        </Dialog>
        <Dialog header={"إرسال اشعار إلى " + selectedUserName} visible={notificationVisible} style={{ width: '40vw', direction: 'rtl' }} onHide={() => setNotificationVisible(false)}>
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>عنوان الاشعار</p>
          <InputText value={notificationTitle} onChange={(e) => setNotificationTitle(e.target.value)} style={{ width: '100%' }} />
          <p style={{ fontWeight: 'bold', marginTop: 20 }}>نص الاشعار</p>
          <InputTextarea value={notificationBody} onChange={(e) => setNotificationBody(e.target.value)} style={{ width: '100%' }} />
          <Button style={{ marginLeft: 'auto', marginRight: 'auto', display: 'block', marginTop: 20 }} onClick={async () => {
            const response = await Apis.sendNotification(notificationTitle, notificationBody, [selectedUser]);
            if (response.status === 'success') {
              toast.success("تم ارسال الاشعار بنجاح");
              setNotificationVisible(false);
            } else {
              setNotificationVisible(false);
            }
          }}>
            ارسال
          </Button>
        </Dialog>
      </div>
      <div>
        <Dialog visible={showPopup} onHide={() => setShowPopup(false)} header="اختر المستخدمين" style={{ direction: "rtl" }} footer={<div><Button onClick={handleActionWithSelectedUsers}>ارسال</Button></div>}>
          <div>
            <InputText placeholder="ابحث حسب الاسم او الرقم التعريفي" value={searchTerm} onChange={handleSearchChange} style={{ marginBottom: 20, width: 350 }} />
            <Button onClick={handleSelectAll} style={{ marginRight: 10 }}>اختيار الكل</Button>
            <div style={{ overflowY: 'scroll', maxHeight: '200px' }}>
              {filteredUsers.map(user => (
                <div key={user.id}>
                  <Checkbox inputId={user.id} value={user.id} checked={selectedUsers.includes(user.id)} onChange={e => {
                    const selectedUser = e.value;
                    setSelectedUsers(prevSelected => {
                      if (prevSelected.includes(selectedUser)) {
                        return prevSelected.filter(id => id !== selectedUser);
                      } else {
                        return [...prevSelected, selectedUser];
                      }
                    });
                  }} />
                  <label htmlFor={user.id} style={{ marginLeft: 10, marginRight: 10 }}>{user.name + " - " + user.idnum}</label>
                </div>
              ))}
            </div>
            <p style={{ fontWeight: 'bold', marginTop: 20 }}>عنوان الاشعار</p>
            <InputText value={notificationTitle} onChange={(e) => setNotificationTitle(e.target.value)} style={{ width: '100%' }} />
            <p style={{ fontWeight: 'bold', marginTop: 20 }}>نص الاشعار</p>
            <InputTextarea value={notificationBody} onChange={(e) => setNotificationBody(e.target.value)} style={{ width: '100%' }} />
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default Users;
